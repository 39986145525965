import React from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import twitter1 from "../Images/icons/twitter-icon.png"

import PropTypes from 'prop-types';
import { faTwitter } from "@fortawesome/free-brands-svg-icons";

function SocialShare({ title, url, img }) {
  const validUrl = url || "https://default.url"; 
  const validImg = img || "";

  return (
    <div style={{ gap: "5px", display: "flex" }}>
      <div className="share-button-container">
        <FacebookShareButton aria-label="Facebook" url={validUrl} quote={title}>
          <FacebookIcon size={27} />
        </FacebookShareButton>
      </div>

      <div className="share-button-container">
        <TwitterShareButton aria-label="Twitter" url={validUrl} title={title}>
          <TwitterIcon size={27} />
    
        </TwitterShareButton>
      </div>

{/* <div className="share-button-container">
  <TwitterShareButton aria-label="Twitter" url={validUrl} title={title}>
  <img
           
                          src={twitter1}
                          width="50"
                          height="50"
                          alt="twitter img"
                  
                          style={{width:"27px",height:"25px",background:"black"}}

                        />
  </TwitterShareButton>
</div> */}


      <div className="share-button-container">
        <LinkedinShareButton aria-label="Linkedin" url={validUrl} title={title} image={validImg}>
          <LinkedinIcon size={27} />
        </LinkedinShareButton>
      </div>
    </div>
  );
}

SocialShare.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  img: PropTypes.string,
};

export default SocialShare;



